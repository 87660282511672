import React from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';

const GET_RECIPES = gql`
  query {
    recipeCollection {
      items {
        title
        recipeUrl
        recipeCardImage {
          url
          title
        }
      }
    }
  }
`;

const RecipePage = () => {
  const { loading, error, data } = useQuery(GET_RECIPES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const recipes = data.recipeCollection.items.map((recipe, index) => ({
    id: index,
    title: recipe.title,
    image: recipe.recipeCardImage.url,
    url: `recipes/${recipe.recipeUrl}`,
  }));

  return (
    <>
      <Helmet>
        <title>Party Snack Recipies - Snackster</title>
        <meta name="description" content={`Find creative and easy Party snack recipes at Snackster. Explore step-by-step guides to make delicious treats and beverages for every occasion. Start cooking today!`} />
      </Helmet>
      <div className="party-more-fun-container py-5">
        <h1 className="party-title mt-5 pt-5 text-center">Recipes</h1>
        <div className="container mt-5 mb-4">
          <div className="row">
            {recipes.map((recipe) => (
              <div key={recipe.id} className="col-md-3">
                <Link to={`/${recipe.url}`}>
                  <div className="recipe-card" style={{ backgroundImage: `url(${recipe.image})` }}>
                    <div className="recipe-title">{recipe.title}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipePage;
