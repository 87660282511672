import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero';
import IconGrid from '../components/iconsSection';
import ProductShowcase from '../components/productCards';
import CrossMarquee from '../components/crossMarquee';
import PartyMoreFun from '../components/recipeSection';
import ChickenNuggetsSlider from '../components/animatedSlider';
import TruckAnimation from '../components/truckAnimation';
import ImageHoverComponent from '../components/imageHover';
import MarqueeSlider from '../components/marqueeSlider';

export default function Home() {
  return (
    <>
      <Helmet>
          <title>Order Party Snacks Online | Frozen Snacks Ready to Cook for Every Occasion - Snackster</title>
          <meta name="description" content={`Order delicious frozen snacks online from Snackster! Perfect for every Party, our ready to cook party snacks make hosting easy. Quick prep, tasty results!`} />
        </Helmet>
      <Hero />
      <IconGrid />
      <ProductShowcase />
      <CrossMarquee />
      <ImageHoverComponent />
      <MarqueeSlider />
      <ChickenNuggetsSlider />
      <PartyMoreFun />
      <TruckAnimation />
    </>
  );
}
