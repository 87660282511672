import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';


const GET_PRODUCTS = gql`
  query {
    productsCollection {
      items {
        productName
        productUrl
        productFeaturedImage {
          url
          title
        }
      }
    }
  }
`;

const ProductPage = () => {
  const { loading, error, data } = useQuery(GET_PRODUCTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const products = data.productsCollection.items.map((product, index) => {
    const [tag, ...nameParts] = product.productName.split(' ');
    const name = nameParts.join(' ');

    return {
      id: index,
      tag,
      name,
      image: product.productFeaturedImage.url,
      url: `products/${product.productUrl}`
    };
  });

  return (
    <>
      <Helmet>
        <title>Ready to Cook Party Snacks - Snackster</title>
        <meta name="description" content={`Explore Snackster India's premium party snacks and beverages. Shop delicious treats and drinks online. Enjoy quality products delivered straight to your doorstep.`} />
      </Helmet>
      <div className="bg-yellow py-5 products">
        <div className="container mt-5">
          <h1 className='text-center py-4'>All Products</h1>
          <div className="row">
            {products.map(product => (
              <div key={product.id} className="col-md-3">
                <div className="product-card p-3 h-100">
                  <div className="product-title mb-2">
                    <span>{product.tag}</span> <br />
                    {product.name.split(' ').map((part, index) => (
                      <React.Fragment key={index}>
                        {part} <br />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="product-image text-center mb-3">
                    <img src={product.image} alt={product.name} className="img-fluid" loading="lazy" />
                  </div>
                  <div className="add-to-cart-btn text-center">
                    <Link to={`/${product.url}`} className="btn add-party-btn">Add To The Party</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPage;
