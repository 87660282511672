import React, { lazy } from "react";
import LandHeader from "../components/landing/LandHeader";
import LandMobileHeader from "../components/landing/LandmobileHeader";
import LandHero from "../components/landing/Hero";
import LandingProductsHyd from "../components/landing/LandingProductsHyd";
import CrossMarquee from "../components/crossMarquee";
import ProductRange from "../components/landing/productRange";
import DqLandPre from "../components/landing/dqPrefooter";
const AudioControl = lazy(() => import('../components/AudioControl'));

export default function LandingPageHyd() {
    return (
        <>
        <AudioControl />
            <LandHeader />
            <LandMobileHeader />
            <LandHero />
            <LandingProductsHyd />
            <CrossMarquee />
            <ProductRange />
            <div className="container  mb-5">
            <h2 className="text-center text-black pt-2 pb-5" style={{fontFamily: "'Blasto Distort', sans-serif", fontSize: "24px"}}>Welcome to Dulquer’s birthday party !</h2>
            <iframe width="100%" height="600" className="d-md-block d-none pb-5 w-75 m-auto" src="https://www.youtube.com/embed/GgWi0EfiWh8?si=sTVrrHm5GhrCaIYn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <iframe width="100%" height="200" className="d-md-none d-block m-auto" src="https://www.youtube.com/embed/GgWi0EfiWh8?si=sTVrrHm5GhrCaIYn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <DqLandPre />
        </>
    );
}