import React from 'react';
import { Helmet } from 'react-helmet-async';

const PartySnack = () => {
    return (
        <>
            <Helmet>
                <title>About Party Snacks - Snackster</title>
                <meta name="description" content={`Discover Snackster Snacks for your party! Ready to cook snacks made with chicken raised without antibiotics, steroids, hormones, or added MSG. Always party ready!`} />
            </Helmet>
            <div className="about-snackster">
                <div className='party-snack py-5'>
                    <div className='container pt-5'>
                        <div className="row text-center p-5">
                            <div className="col mt-5">
                                <h1 className="text-uppercase fw-bold">Party <span>Snack?<br /> Snackster's</span> Got Your Back!</h1>
                                <p className="pt-5">
                                    We love how snacks are the life of every party. Secretly, you do, too! Oh, we also know how you love being in charge of the party and getting things done by yourself. You thought we didn’t know that? Nah, we knew. So to make every party a little extra merry, we are bringing forth the magic of snacks, to you - Something, that you can make in a jiffy.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className='spotify-section py-5 bg-purple'>
                    <div className='container'>
                        <div className="row align-items-center text-light p-5">
                            <div className='col-md-6'>
                                <img
                                    src="/images/spotify-about.png" // Replace with actual image URL
                                    alt="Party"
                                    className="img-fluid mb-4"
                                    loading="lazy"
                                />
                            </div>
                            <div className="col-md-6">
                                <h2 className="fw-bold">Shut up and bounce coz it’s the time to disco! </h2>
                                <img src='/images/Spotify_logo.svg' alt='spotify logo' className='w-50 pt-4' loading="lazy" /><br />
                                <a href="https://open.spotify.com/playlist/0IwUo1OL9ve8ZN3TqukErn?si=EHt5JCh1QviqkV0Qq49zLQ" className="mt-3 pt-4" target="_blank" rel="noopener noreferrer">
                                    <button className="btn btn-warning text-uppercase fw-bold mt-3">Party Playlist</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className='about-sneha py-5 bg-warning'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <center> <img
                                    src="/images/about-sneha-chicken.png" // Replace with actual chicken logo URL
                                    alt="Sneha Chicken Logo"
                                    className="mb-4 w-50"
                                    loading="lazy"
                                /></center>
                            </div>
                            <div className='col-md-6'>
                                <p className="py-4">
                                    The chicken from Sneha Chicken, a 40-year legacy brand, really wanted to be a part of this
                                    big party plan. Therefore, we present to you Snackster – to bring that crunch to every party.
                                </p>
                                <img src='/images/sneha-40years-logo.svg' alt='sneha 40 years logo' loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartySnack;
