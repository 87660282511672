import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Lottie from 'lottie-react';
import truckAnimation from '../animations/truck.json'; // Path to your Lottie JSON file

gsap.registerPlugin(ScrollTrigger);

const TruckAnimation = () => {
  const truckRef = useRef(null);

  useEffect(() => {
    const truckElement = truckRef.current;

    const animationSettings = {
      x: () => -window.innerWidth, // Move truck from right to left
      ease: 'none',
      scrollTrigger: {
        trigger: truckElement,
        scrub: 0.5, // Reduce scrub duration for smoother animation
        markers: false,
        start: 'top 100%', // Default start
        end: 'bottom 50%', // Default end
      },
    };

    const updateAnimationSettings = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width <= 390 && height <= 844) {
        // For mobile devices 390x844
        animationSettings.scrollTrigger.start = 'top 80%'; // Start when the top of the truck is 80% down the viewport
        animationSettings.scrollTrigger.end = 'bottom 30%'; // End when the bottom of the truck is 30% up the viewport
      } else if (width <= 768) {
        // For tablets and smaller screens
        animationSettings.scrollTrigger.start = 'top 90%'; // Start when the top of the truck is 90% down the viewport
        animationSettings.scrollTrigger.end = 'bottom 40%'; // End when the bottom of the truck is 40% up the viewport
      } else {
        // For larger screens
        animationSettings.scrollTrigger.start = 'top 100%'; // Start when the top of the truck is 100% down the viewport
        animationSettings.scrollTrigger.end = 'bottom 75%'; // End when the bottom of the truck is 50% up the viewport
      }

      ScrollTrigger.refresh();
    };

    updateAnimationSettings();

    window.addEventListener('resize', updateAnimationSettings);

    const animation = gsap.to(truckElement, animationSettings);

    return () => {
      window.removeEventListener('resize', updateAnimationSettings);
      if (animation.scrollTrigger) {
        animation.scrollTrigger.kill();
      }
    };
  }, []);

  const containerStyle = {
    height: window.innerWidth <= 390 && window.innerHeight <= 844 ? '15vh' : '55vh', // Adjust height based on screen size
    position: 'relative',
    willChange: 'transform', // Hint to browser for optimization
  };

  return (
    <div className='mt-5 d-none d-md-block' style={containerStyle}>
      <div
        ref={truckRef}
        style={{
          position: 'absolute',
          top: '50%',
          left: '100%',
          transform: 'translateY(-50%)',
          width: '100%',
        }}
      >
        <Lottie
          animationData={truckAnimation}
          loop={true} // Allow the animation to loop
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
};

export default TruckAnimation;
